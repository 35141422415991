import { ChevronLeft, ChevronRight } from 'react-feather'
import { PUBLIC_IMAGE_URL } from '../config/setting'
import { setAnswer, setTitle } from '../helper/helper'
import SelectedOptionList from './selectedOptionList'
import { useTranslation } from 'react-i18next'

const Answers = ({
  categoryFromApi,
  showIndex,
  selectedOptions,
  selectedAnswer,
  selectedLanguage,
  loading
}) => {

  const {i18n} = useTranslation()

  return (
    <>
      <div className="main-contain">
        {loading ? (
          <div class="spinner-border text-danger" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          categoryFromApi?.length > 0 &&
          categoryFromApi.map((question, queIndex) => {
            return (
              queIndex === showIndex && (
                <>
                  <div className={Object.keys(selectedOptions).length > 0 ? "question" : "question mb-5"}>
                    <h4>{setTitle(question, selectedLanguage)}</h4>
                  </div>
                  <SelectedOptionList
                    selectedOptions={selectedOptions}
                    selectedLanguage={selectedLanguage}
                  />
                  <div className="answers">
                    {question[Object.keys(question)].answers?.length > 0 &&
                      question[Object.keys(question)].answers.map(
                        (ans, ansIndex) => {
                          return (
                            <>
                              <div
                                className={
                                  showIndex === 0
                                    ? 'ans-box-type1 cursor-pointer selectHairCareOptionBtn'
                                    : 'ans-box cursor-pointer selectHairCareOptionBtn'
                                }
                                key={ansIndex}
                                onClick={() =>
                                  selectedAnswer(Object.keys(question), ans)
                                }
                              >
                                <div className="inner-ans">
                                  {showIndex === 0 && (
                                    <img
                                      src={
                                        ans?.ans_en === 'Straight'
                                          ? PUBLIC_IMAGE_URL + 'straight.svg'
                                          : ans?.ans_en === 'Wavy'
                                            ? PUBLIC_IMAGE_URL + 'wavy01.svg'
                                            : PUBLIC_IMAGE_URL + 'curly.svg'
                                      }
                                      className="type-img"
                                      alt=""
                                    />
                                  )}
                                  <p>
                                    {setAnswer(ans, selectedLanguage)}
                                  </p>
                                </div>
                                {i18n.language === "ar" ? <ChevronLeft className="margin-right" /> : <ChevronRight className="margin-right" /> }
                              </div>
                            </>
                          )
                        }
                      )}
                  </div>
                </>
              )
            )
          })
        )}
      </div>
    </>
  )
}

export default Answers
