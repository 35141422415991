export const setTitle = (question, selectedLanguage) => {
    switch (selectedLanguage) {
      case 'en':
        return question[Object.keys(question)]?.questions?.que_title_en
      case 'es':
        return question[Object.keys(question)]?.questions?.que_title_es
      case 'fr':
        return question[Object.keys(question)]?.questions?.que_title_fr
      case 'cn':
        return question[Object.keys(question)]?.questions?.que_title_zh
      case 'ru':
        return question[Object.keys(question)]?.questions?.que_title_ru
      case 'ar':
        return question[Object.keys(question)]?.questions?.que_title_ar
      default:
        return question[Object.keys(question)]?.questions?.que_title_en
    }
  }
  
export const setAnswer = (ans, selectedLanguage) => {
    switch (selectedLanguage) {
      case 'en':
        return ans?.ans_en
      case 'es':
        return ans?.ans_es
      case 'fr':
        return ans?.ans_fr
      case 'cn':
        return ans?.ans_zh
      case 'ru':
        return ans?.ans_ru
      case 'ar':
        return ans?.ans_ar
      default:
        return ans?.ans_en
    }
  }
  

  export const setProductTitle = (title, selectedLanguage) => {
    switch (selectedLanguage) {
      case 'en':
        return title?.title_english
      case 'es':
        return title?.title_spanish
      case 'fr':
        return title?.title_french
      case 'cn':
        return title?.title_chinese
      case 'ru':
        return title?.title_russian
      case 'ar':
        return title?.title_arabic
      default:
        return title?.title_english
    }
  }

  export const setProductDescription = (desc, selectedLanguage) => {
    switch (selectedLanguage) {
      case 'en':
        return desc?.desc_english
      case 'es':
        return desc?.desc_spanish
      case 'fr':
        return desc?.desc_french
      case 'cn':
        return desc?.desc_chinese
      case 'ru':
        return desc?.desc_russian
      case 'ar':
        return desc?.desc_arabic
      default:
        return desc?.desc_english
    }
  }