import { PUBLIC_IMAGE_URL } from '../config/setting'
const Footer = () => {
  return (
    <>
      <footer>
        <a href='https://store.oliviagarden.com/'>
          <div>
            <img src={PUBLIC_IMAGE_URL + 'oliviadlogo.svg'} alt="" />
          </div>
          <h6 className="footer-text">Back to OliviaGarden.com</h6>
          <p>Copyright ©{new Date().getFullYear()} Olivia Garden</p>
        </a>
      </footer>
    </>
  )
}

export default Footer
