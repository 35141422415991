import Footer from '../components/footer'
import Header from '../components/header'
import Description from '../components/description'
import { WEBGETPRODUCT, WEBGETPRODUCTCATEGORY } from '../config/apiConstant'
import { useEffect, useState } from 'react'
import Answers from '../components/answers'
import AllProduct from '../components/allProduct'
import ProductDetail from '../components/productDetails'
import { PUBLIC_IMAGE_URL } from '../config/setting'
import useNetwork from '../hook/useNetwork'

const Home = () => {
  const [categoryFromApi, setCategoryFromApi] = useState([])
  const [showIndex, setShowIndex] = useState(0)
  const [selectedOptions, setSelectedOption] = useState({})
  const [selectedLanguage, setSelectedLanguage] = useState('en')
  const [loading, setLoading] = useState(false)
  const [showProduct, setShowProduct] = useState(false)
  const [productDetail, setProductDetail] = useState({})
  const [filterData, setFilterData] = useState([])
  const [showProductIndex, setShowProductIndex] = useState(0)
  const { online } = useNetwork()

  //get filter product
  const getFilterProduct = async (selectedAnsObj) => {
    const categoryArr = []
    //create category array
    for (const property in selectedAnsObj) {
      categoryArr.push({
        que_id: selectedAnsObj[property]?.que_id,
        ans_id: selectedAnsObj[property]?.ans_id
      })
    }

    fetch(WEBGETPRODUCT, {
      method: 'post',
      body: JSON.stringify(categoryArr),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(async (response) => {
        const res = await response.json()
        setFilterData(res?.data?.content?.rows)
        setShowProduct(true)
      })
      .catch((error) => console.log(error))
  }

  //selected ans
  const selectedAnswer = (name, value) => {
    setSelectedOption({ ...selectedOptions, [name]: value })
    const selectedAnsObj = { ...selectedOptions, [name]: value }

    if (!value?.is_last_answer) {
      setShowIndex(showIndex + 1)
    } else {
      getFilterProduct(selectedAnsObj)
    }
  }

  //get all category
  const getAllCategory = async () => {
    setLoading(true)
    fetch(WEBGETPRODUCTCATEGORY, {
      method: 'get'
    })
      .then(async (response) => {
        const res = await response.json()
        const { questions } = res?.data

        const finalObj = []

        if (questions && questions?.length > 0) {
          questions.map((item) =>
            finalObj.push({
              [item.que_title_en]: { answers: item?.answers, questions: item }
            })
          )
        }

        setCategoryFromApi(finalObj)
        localStorage.setItem('categories', JSON.stringify(finalObj))
        setLoading(false)
      })
      .catch((error) => {
        console.log('error call', error)
        const DataForSet = JSON.parse(localStorage.getItem('categories'))
        setCategoryFromApi(DataForSet)
        setLoading(false)
      })
  }

  useEffect(() => {
    getAllCategory()
  }, [])

  return (
    <>
      <div className="home">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-4 col-xl-4 col-lg-block col-none"
              style={{ zIndex: '1' }}
            >
              <div className="modalImgDiv">
                <img
                  src={PUBLIC_IMAGE_URL + 'App_Desktop_v11.png'}
                  className="img-fluid home-model"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-xl-4 col-lg-block col-sm-8 col-12 mx-sm-auto mx-auto h-100">
              {online ? (
                <div className="selection-section">
                  <div className="w-100">
                    <Header
                      setShowIndex={setShowIndex}
                      showIndex={showIndex}
                      selectedOptions={selectedOptions}
                      setSelectedOption={setSelectedOption}
                      setSelectedLanguage={setSelectedLanguage}
                      selectedLanguage={selectedLanguage}
                      setShowProduct={setShowProduct}
                      showProduct={showProduct}
                      productDetail={productDetail}
                      setProductDetail={setProductDetail}
                    />
                    {!showProduct ? (
                      <>
                        <Description selectedLanguage={selectedLanguage} />
                        <Answers
                          setShowIndex={setShowIndex}
                          showIndex={showIndex}
                          categoryFromApi={categoryFromApi}
                          selectedAnswer={selectedAnswer}
                          selectedOptions={selectedOptions}
                          selectedLanguage={selectedLanguage}
                          loading={loading}
                        />
                      </>
                    ) : (
                      <>
                        {Object.keys(productDetail)?.length > 0 ? (
                          <>
                            <ProductDetail productDetail={productDetail} filterData={filterData} showProductIndex={showProductIndex}/>
                          </>
                        ) : (
                          <AllProduct
                            selectedOptions={selectedOptions}
                            selectedLanguage={selectedLanguage}
                            setProductDetail={setProductDetail}
                            setShowProductIndex={setShowProductIndex}
                            filterData={filterData}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <Footer />
                </div>
              ) : (
                <div className='text-center'>
                  <img src={PUBLIC_IMAGE_URL + 'oliviadlogo.svg'} alt="" className='mt-5'/>
                  <div className="">
                    <div>
                      <img
                        src={PUBLIC_IMAGE_URL + 'no-connection-bro.svg'}
                        width={200}
                        alt=""
                      />
                      <h3 className="offline-title">You Are Offline</h3>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-4 col-xl-4 col-lg-block col-none">
              <div className="modalImgDiv">
                <img
                  src={PUBLIC_IMAGE_URL + 'App_Desktop_v12.png'}
                  className="img-fluid home-model"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
