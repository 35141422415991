import { useRef } from 'react'
import { ArrowDownCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { PRODUCT_SMALL_BASE_URL, PUBLIC_IMAGE_URL } from '../config/setting'
import SelectedOptionList from './selectedOptionList'

const AllProduct = ({
  selectedOptions,
  selectedLanguage,
  setProductDetail,
  setShowProductIndex,
  filterData
}) => {
  const { t } = useTranslation()

  const scrollRef = useRef(null);

  const handleClick = () => {
    scrollRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  const showProduct = (index, product) => {
    setProductDetail(product)
    setShowProductIndex(index)
  }

  return (
    <>
      <SelectedOptionList
        selectedOptions={selectedOptions}
        selectedLanguage={selectedLanguage}
      />
      {filterData?.length > 0 && (
        <>
          <p className="brushCountTitle">
            {filterData?.length} {t('brush')}
          </p>
          <p className="seeMoreDetailsText">{t('see')}</p>
        </>
      )}
      <div className="show-small-imgs container-fluid">
        {filterData?.length > 0 ? (
          <div className="row hairComb-row">
            {filterData.map((product, index) => {
              return (
                <div className={'col-12 d-flex center-img'}  ref={scrollRef}>
                  <div className={'hairComb-width'}>
                    <img
                      src={`${PRODUCT_SMALL_BASE_URL}${product?.p_image_small}.png`}
                      key={index}
                      width={160}
                      height={56}
                      className={
                        index % 2 !== 0
                          ? 'mt-2 cursor-pointer'
                          : 'rotate-img mt-2 cursor-pointer'
                      }
                      onClick={() => showProduct(index, product)}
                      alt=""
                    />
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div>
            <img src={PUBLIC_IMAGE_URL + 'No data.gif'} width={250} alt=""/>
            <p className="p-0 m-0 text-center offline-title">{t('noBrush')}</p>
          </div>
        )}
      </div>
      {filterData && filterData.length > 3 && <div><p className='m-0 p-3 scroll-more-title' onClick={handleClick}>{t('scroll')} <ArrowDownCircle/></p></div>}
    </>
  )
}

export default AllProduct
