import { ChevronRight } from 'react-feather'
import { setAnswer } from '../helper/helper'

const SelectedOptionList = ({ selectedOptions, selectedLanguage }) => {
  return (
    <>
    {console.log(selectedOptions)}
      {Object.keys(selectedOptions)?.length > 0 && (
        <div className="d-flex flex-wrap justify-content-center answer-listDiv">
          {Object.values(selectedOptions).map((selected, index) => {
            return (
              <>
                <p className="answer-list m-0">
                  {setAnswer(selected, selectedLanguage)}
                  {index !== Object.values(selectedOptions)?.length - 1 && (
                    <ChevronRight size={15} />
                  )}
                </p>
              </>
            )
          })}
        </div>
      )}
    </>
  )
}

export default SelectedOptionList
