import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../pages/home";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import NotFound from "../pages/404";

const Routers = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="*/" component={NotFound} />
    </Switch>
  );
};

export default Routers;
