import { PUBLIC_IMAGE_URL } from '../config/setting'

const NotFound = () => {
  return (
    <div className="text-center mt-5 not-found">
      <img src={PUBLIC_IMAGE_URL + 'oliviadlogo.svg'} alt="" />
      <h1>404</h1>
      <h3>Page Not Found</h3>
      <a href="/">
        <button className="btn-shop">Back To Home</button>
      </a>
    </div>
  )
}

export default NotFound
