import { PRODUCT_BASE_URL } from '../config/setting'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useTranslation } from 'react-i18next'
import { setProductDescription, setProductTitle } from '../helper/helper'

const ProductDetail = ({ filterData, showProductIndex }) => {

  const { t,i18n } = useTranslation()

  const { language } = i18n

  function SampleNextArrow(props) {
    const { className, style, onClick } = props

    return (
      <div
        className={className}
        style={{
          ...style,
          display: filterData.length === 1 ? 'none' : 'block',
          background: 'red'
        }}
        onClick={onClick}
      />
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: filterData.length === 1  ? 'none' : 'block',
          background: 'green'
        }}
        onClick={onClick}
      />
    )
  }

  const settings = {
    dots: false,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: i18n.language === 'ar' && showProductIndex === 0 ? filterData.length - 1 : i18n.language === 'ar' && showProductIndex ===  filterData.length - 1 ? 0 : showProductIndex,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    rtl: i18n.language === 'ar' ? true : false
  }

  return (
    <>
      {filterData?.length > 0 && (
        <div>
          <Slider {...settings}>
            {filterData.map((product, index) => {
              return (
                <>
                  <div className="title-postion">
                    <h4 style={{ color: product?.p_title_color }}>
                      {setProductTitle(product, language)}
                    </h4>
                  </div>
                  <div className="img-detail-box">
                    <img
                      src={`${PRODUCT_BASE_URL}${product?.p_image}.png`}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <a href={product?.p_web_url} target="_blank" rel="noreferrer">
                    <div className="text-center">
                      <button className='d-block m-auto btn-shop'>{t('shopBtn')}</button>
                    </div>
                  </a>
                  {product?.desc_english && (
                    <div className="description-postion">
                      <h4>
                        {product?.p_name}
                      </h4>
                      <h3>
                      {setProductDescription(product, language)}
                      </h3>
                    </div>
                  )}
                </>
              )
            })}
          </Slider>
        </div>
      )}
    </>
  )
}

export default ProductDetail
