import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    translation: {
      title: 'HAIR BRUSH GUIDE',
      description: `Find out which brush is right for your hair type & desired style
      Fast, simple & customized to you!`,
      brush: 'brushes based on your selections',
      see: 'To see more details, select a brush image below.',
      scroll: 'Scroll down to see more results',
      shopBtn: 'Shop Now',
      noBrush: 'No Brush Found'
    }
  },
  fr: {
    translation: {
      title: 'GUIDE DES BROSSES À CHEVEUX',
      description: `Découvrez quelle brosse convient à votre type de cheveux et au style souhaité 
      Rapide, simple et personnalisé pour vous!`,
      brush: 'pinceaux en fonction de vos sélections',
      see: 'Pour voir plus de détails, sélectionnez une image de pinceau ci-dessous.',
      scroll: 'Faites défiler vers le bas pour voir plus de résultats',
      shopBtn: 'Achetez maintenant',
      noBrush: 'Aucun pinceau trouvé'
    }
  },
  es: {
    translation: {
      title: 'GUÍA DE CEPILLOS DE PELO',
      description: `Descubre qué cepillo es el adecuado para tu tipo de cabello y el estilo deseado 
      Rápido, simple y personalizado para ti!`,
      brush: 'pinceles basados ​​en sus selecciones',
      see: 'Para ver más detalles, seleccione una imagen de pincel a continuación.',
      scroll: 'Desplácese hacia abajo para ver más resultados',
      shopBtn: 'Compra ahora',
      noBrush: 'No se encontró ningún cepillo'
    }
  },
  ru: {
    translation: {
      title: 'РУКОВОДСТВО ПО ЩЕТКЕ ДЛЯ ВОЛОС',
      description: `Узнайте, какая расческа подходит для вашего типа волос и желаемого стиля 
      Быстро, просто и индивидуально для вас!`,
      brush: 'кисти на основе вашего выбора',
      see: 'Чтобы увидеть больше деталей, выберите изображение кисти ниже.',
      scroll: 'Прокрутите вниз, чтобы увидеть больше результатов',
      shopBtn: 'Купить сейчас',
      noBrush: 'Кисть не найдена'
    }
  },
  cn: {
    translation: {
      title: '发刷指南',
      description: `找出适合您的发型和所需发型的梳子
      快速、简单且为您量身定制`,
      brush: '根据您的选择画笔',
      see: '要查看更多详细信息，请选择下面的画笔图像。',
      scroll: '向下滚动以查看更多结果',
      shopBtn: '现在去购物',
      noBrush: '没有找到画笔'
    }
  },
  ar: {
    translation: {
      title: 'دليل فرشاة الشعر',
      description: `اعرف أي فرشاة هي الأنسب لنوع شعرك والنمط المطلوب 
      سريع، بسيط ومخصص لك!`,
      brush: 'فرش بناءً على اختياراتك',
      see: 'لرؤية المزيد من التفاصيل، حدد صورة الفرشاة أدناه.',
      scroll: 'قم بالتمرير لأسفل لرؤية المزيد من النتائج',
      shopBtn: 'تسوق الآن',
      noBrush: 'لم يتم العثور على فرشاة'
    }
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
