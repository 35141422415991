import { ArrowLeft, ArrowRight, CheckCircle, Globe, Home } from 'react-feather'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { PUBLIC_IMAGE_URL } from '../config/setting'

const Header = ({
  setShowIndex,
  showIndex,
  selectedOptions,
  setSelectedOption,
  setSelectedLanguage,
  selectedLanguage,
  setShowProduct,
  showProduct,
  setProductDetail,
  productDetail
}) => {
  const { t, i18n } = useTranslation()

  //go back
  const goBack = () => {
    if (Object.keys(productDetail)?.length > 0) {
      setProductDetail({})
    } else if (showProduct) {
      setShowProduct(false)
    } else {
      const lastKey = Object.keys(selectedOptions).pop()
      delete selectedOptions[lastKey]
      setShowIndex(showIndex - 1)
    }
  }

  const goToHome = (e) => {
    e.preventDefault()
    setShowIndex(0)
    setSelectedOption({})
    setProductDetail({})
    setShowProduct(false)
  }

  const handleLanguage = (lang) => {
    let setRtl = lang === 'ar' ? 'rtl' : 'ltr'

    document.documentElement.dir = setRtl

    setSelectedLanguage(lang)
    i18n.changeLanguage(lang)
    document.documentElement.lang = i18n.language
  }

  //constant language data
  const Languages = [
    {
      language: 'English',
      code: 'en',
      image: PUBLIC_IMAGE_URL + 'flag1.png'
    },
    {
      language: 'Spanish',
      code: 'es',
      image: PUBLIC_IMAGE_URL + 'flag2.png'
    },
    {
      language: 'French',
      code: 'fr',
      image: PUBLIC_IMAGE_URL + 'flag3.png'
    },
    {
      language: 'Russian',
      code: 'ru',
      image: PUBLIC_IMAGE_URL + 'flag4.png'
    },
    {
      language: 'Chinese',
      code: 'cn',
      image: PUBLIC_IMAGE_URL + 'flag5.png'
    },
    {
      language: 'Arabic',
      code: 'ar',
      image: PUBLIC_IMAGE_URL + 'flag6.png'
    }
  ]

  return (
    <>
      <div className="header px-1">
        <div className="d-flex justify-content-between align-items-center">
          <p className="demoElement">
            {showIndex !== 0 && (
              i18n.language === "ar" ? <ArrowRight className="cursor-pointer" onClick={() => goBack()}  /> : <ArrowLeft className="cursor-pointer" onClick={() => goBack()} />
            )}
          </p>
          <h3 className="title">{t('title')}</h3>

          {showProduct ? (
            <UncontrolledDropdown className="nav-item changeLang">
              <DropdownToggle
                href="/"
                tag="a"
                className="nav-link"
                onClick={(e) => goToHome(e)}
              >
                <Home color="#000" />
              </DropdownToggle>
            </UncontrolledDropdown>
          ) : (
            <>
              <UncontrolledDropdown className={`nav-item changeLang ${i18n.language === 'ar' && 'arabic'}`} dir='ltr'>
                <DropdownToggle
                  href="/"
                  tag="a"
                  className="nav-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Globe color="#000" />
                </DropdownToggle>
                <DropdownMenu>
                  <p className="text-center">
                    <b>Change Language</b>
                  </p>
                  {Languages.map((lan, index) => {
                    return (
                      <>
                        <DropdownItem
                          onClick={() => handleLanguage(lan.code)}
                          key={index}
                        >
                          <img src={lan.image} className="flg-img" alt="" />
                          <span>
                            {lan.language}
                            {lan.code === selectedLanguage && (
                              <CheckCircle
                                size={14}
                                color={'#9E0022'}
                                className="ml-1"
                              />
                            )}
                          </span>
                        </DropdownItem>
                      </>
                    )
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          )}
        </div>
      </div>
      {/* <hr /> */}
    </>
  )
}

export default Header
