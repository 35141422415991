import React from 'react'
import Routers from './routes/router'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

function App() {
  return (
    <I18nextProvider  i18n={i18n}>
      <BrowserRouter>
        <Routers />
      </BrowserRouter>
    </I18nextProvider>
  )
}

export default App
